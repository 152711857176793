<template>
  <v-app min-height="0px">
    <v-data-table
      :headers="headers"
      :items="users"
      :loading="loadingVariable"
      sort-by="lastname"
      class="elevation-1"
      loading-text="Bitte warten... Benutzer werden geladen"
    >
      <template v-slot:footer.page-text="items">
        {{ items.pageStart }} von {{ items.itemsLength }}
      </template>
      <template v-slot:item.admin="{ item }">
        {{ item.admin === 1 ? "JA" : "NEIN" }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on">
                Neuer Benutzer
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.firstname"
                        label="Vorname"
                        filled
                        auto-grow
                        background-color="#f7f7f7"
                        name="input-7-1"
                        dense
                        clearable
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.lastname"
                        label="Nachname"
                        filled
                        auto-grow
                        background-color="#f7f7f7"
                        name="input-7-1"
                        dense
                        clearable
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="10" md="10">
                      <v-text-field
                        v-model="editedItem.email"
                        label="E-Mail"
                        filled
                        auto-grow
                        background-color="#f7f7f7"
                        name="input-7-1"
                        dense
                        clearable
                        :rules="[rules.required, rules.email]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="10" md="10">
                      <v-text-field
                        v-model="editedItem.invoice_email"
                        label="E-Mail (Rechnung)"
                        filled
                        auto-grow
                        background-color="#f7f7f7"
                        name="input-7-1"
                        dense
                        clearable
                        :rules="[rules.required, rules.email]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="10" md="10">
                      <v-text-field
                        v-model="editedItem.password"
                        :label="
                          editedIndex < 0 ? 'Passwort' : 'Passwort (optional)'
                        "
                        :type="'password'"
                        class="input-group--focused"
                        filled
                        auto-grow
                        background-color="#f7f7f7"
                        name="input-7-1"
                        dense
                        clearable
                        hint="Mindestens 8 Zeichen"
                        :rules="[rules.required, rules.min]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="10" md="10">
                      <v-text-field
                        v-model="editedItem.position"
                        label="Position"
                        filled
                        auto-grow
                        background-color="#f7f7f7"
                        name="input-7-1"
                        dense
                        clearable
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.phone"
                        label="Telefon"
                        filled
                        auto-grow
                        background-color="#f7f7f7"
                        name="input-7-1"
                        dense
                        clearable
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.fax"
                        label="Fax"
                        filled
                        auto-grow
                        background-color="#f7f7f7"
                        name="input-7-1"
                        dense
                        clearable
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.office"
                        label="Büro"
                        filled
                        auto-grow
                        background-color="#f7f7f7"
                        name="input-7-1"
                        dense
                        clearable
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12" sm="6" md="12"
                    >
                      <v-select
                        v-model="editedItem.admin"
                        :items="adminOptions"
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>
                  <div v-if="formInvalid" class="fillAllFieldsMessage">
                    {{ formInvalidMessage }}
                  </div>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="close">
                  Abbrechen
                </v-btn>
                <v-btn color="primary darken-1" text @click="save">
                  Senden
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Soll der Benutzer unwiderruflich gelöscht werden?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="closeDelete"
                  >Abbrechen
                </v-btn>
                <v-btn color="primary darken-1" text @click="deleteItemConfirm"
                  >Ok
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!--          <v-dialog v-model="dialogChangePassword" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">Set new password</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col v-if="editedIndex < 0" cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.password"
                        label="Password"
                        :type="'password'"
                        class="input-group&#45;&#45;focused"
                        hint="At least 8 characters"
                        :rules="[rules.required, rules.min]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="closePassword">
                  Cancel
                </v-btn>
                <v-btn color="primary darken-1" text @click="changePassword">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>-->
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          title="Benutzer bearbeiten"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          title="Benutzer löschen"
          small
          class="mr-2"
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
        <!--        <v-icon
          title="Set new password for user"
          small
          @click="changePassword(item)"
        >
          mdi-lock-reset
        </v-icon>-->
      </template>
      <template v-slot:no-data>
        <!--        <v-btn color="primary" @click="initialize">
            Reset
          </v-btn>-->
        Leer
      </template>
    </v-data-table>
  </v-app>
</template>

<script>
import ApiService from "@/core/services/api.service";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import Vue from "vue";
//import { LOGIN } from "@/core/services/store/auth.module";
// Init plugin
Vue.use(Loading);

export default {
  name: "TableCrud",
  props: {
    loadingVariable: {
      type: Boolean,
      default: false
    },
    users: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data: () => ({
    formInvalid: false,
    rules: {
      fullPage: false,
      required: value => !!value || "Pflichtfeld",
      formHasErrors: false,
      min: v => (v && v.length >= 8) || "Min 8 characters",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      }
    },
    adminOptions: [
      { text: "Standardbenutzer", value: 0 },
      { text: "Administrator", value: 1 }
    ],
    dialog: false,
    dialogChangePassword: false,
    dialogDelete: false,
    headers: [
      { text: "Vorname", value: "firstname" },
      { text: "Nachname", value: "lastname" },
      { text: "E-Mail", value: "invoice_email" },
      { text: "Administrator", value: "admin" },
      { text: "Position", value: "position" },
      { text: "Telefon", value: "phone" },
      { text: "Büro", value: "office" },
      { text: "Aktionen", value: "actions", sortable: false }
    ],
    editedIndex: -1,
    editedItem: {
      id: "",
      firstname: "",
      lastname: "",
      password: "",
      email: "",
      invoice_email: "",
      position: "",
      admin: 0
    },
    formInvalidMessage: "Bitte alle Pflichtfelder ausfüllen",
    defaultItem: {
      firstname: "",
      lastname: "",
      email: "",
      invoice_email: "",
      office: "",
      admin: 0
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Benutzer erstellen"
        : "Benutzer editieren";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogPassword(val) {
      val || this.closePassword();
    }
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel
      });

      ApiService.delete("users/delete/?id=" + this.editedItem.id)
        // eslint-disable-next-line no-unused-vars
        .then(({ data }) => {
          this.users.splice(this.editedIndex, 1);
          this.closeDelete();
          loader.hide();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(({ response }) => {
          loader.hide();
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    changePassword() {
      this.dialogChangePassword = true;
    },

    closePassword() {
      this.dialogChangePassword = false;
    },

    save() {
      if (
        !!this.editedItem.firstname &&
        !!this.editedItem.lastname &&
        !!this.editedItem.email &&
        (this.editedIndex > -1 ||
          (!!this.editedItem.password &&
            this.editedItem.password.length >= 8)) &&
        !!this.editedItem.position &&
        !!this.editedItem.phone &&
        !!this.editedItem.office
      ) {
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: true,
          onCancel: this.onCancel
        });

        this.editedItem["admin"] = this.editedItem["admin"] ? 1 : 0;

        if (this.editedIndex > -1) {
          ApiService.put("users/update", this.editedItem)
            .then(({ data }) => {
              if (data.user) {
                //this.$store.dispatch(LOGIN, data);
                if (this.editedIndex > -1) {
                  Object.assign(this.users[this.editedIndex], data.user[0]);
                } else {
                  this.users.push(data.user[0]);
                }
                this.resetForm();
                this.close();
              }
              loader.hide();
            })
            .catch(({ response }) => {
              this.formInvalidMessage = response.data.message;
              this.formInvalid = true;
              loader.hide();
            });
        } else {
          ApiService.post("auth/register", this.editedItem)
            .then(({ data }) => {
              if (data.user) {
                if (this.editedIndex > -1) {
                  Object.assign(this.users[this.editedIndex], data.user);
                } else {
                  this.users.push(data.user);
                }
                this.resetForm();
                this.close();
              }
              loader.hide();
            })
            .catch(({ response }) => {
              this.formInvalidMessage = response.data.message;
              this.formInvalid = true;
              loader.hide();
            });
        }
      } else this.formInvalid = true;
    },
    resetForm() {
      this.formInvalid = false;
      this.editedItem = {
        firstname: "",
        lastname: "",
        password: "",
        email: "",
        invoice_email: "",
        position: "",
        admin: 0
      };
    }
  }
};
</script>

<style scoped>
.fillAllFieldsMessage {
  color: red;
}
.v-data-footer__select {
  display: none !important;
}
</style>
